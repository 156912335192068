
import React, { useRef, useState, useEffect } from 'react';
import Head from 'next/head'
import Image from 'next/image';
import Link from 'next/link';
import Router from 'next/router';
import { groupBy } from 'lodash'
import { META, Logo } from '@/lib/constants'
import { PostItem, Layout, CtaBtn, BannerCarousel, Dropdown, BlueButton, LeftTriangleOnTop, RightTriangleOnTop, ScrollToTopBtn } from '@/components/index';
import styles from '@/styles/Home.module.css'
import { CheckIcon, SelectorIcon, ChevronDownIcon, CalendarIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Pagination, Navigation
} from 'swiper/core';
SwiperCore.use([Pagination, Navigation]);

export default function Index() {

  const INDEX_COPYWRITTING = {

    blog: {
      post_cta: {
        title: '觀看更多文章'
      },
      title: '聚落好文',
      posts: [{
        title: '辦公空間新革命',
        description: '創意&啟發性的空間、設施符合工作需求、多種風格裝潢、不同風格的環境，為工作與生活帶來更多可能性',
        src: '/banner/pexels-ken-tomita-389818@2x.png',
      }, {
        title: '辦公空間新革命',
        description: '創意&啟發性的空間、設施符合工作需求、多種風格裝潢、不同風格的環境，為工作與生活帶來更多可能性',
        src: '/banner/pexels-ken-tomita-389818@2x.png',
      }, {
        title: '辦公空間新革命',
        description: '創意&啟發性的空間、設施符合工作需求、多種風格裝潢、不同風格的環境，為工作與生活帶來更多可能性',
        src: '/banner/pexels-ken-tomita-389818@2x.png',
      }]
    },
    steps: [{
      // title: '選擇空間設施',
      title: '選擇日期與空間條件',
      src: '/icons/Group 355.svg'
    }, {
      // title: 'App選位預約',
      title: 'App 預約座位或空間',
      src: '/icons/Group 356.svg'
    }, {
      // title: '體溫感測管控',
      title: '自動體溫感測管控',
      src: '/icons/Group 358.svg'
    }, {
      // title: '實名制QRcode進出',
      title: '掃描 QR Code 進出',
      src: '/icons/Group 357.svg'
    }],
    features: [{
      // title: '享聚落、享工作',
      // description: '重新定義工作的場景。打破工作界線，讓你專注在工作，工作更生活，工作更智慧，工作更聰明，自由的工作',
      title: '共享中的獨享',
      description: `首創訂閱制空間會籍：App 上隨時預約座位，享受工作、學習、社交、創意空間零時差。<br/>
      空間設計滿足共享環境中相互激盪想法，獨享單人亭中專注自我實現。`,
      item: '/banner/home/feature-1.jpg'
    }, {
      // title: '豐富且多元空間',
      // description: '創意&啟發性的空間及設施，讓你不論辦公、交流、會議討論或是舉辦活動發表會、培訓課程、線上直播等、xarehub享聚落都能一站式滿足你的需求',
      title: '共創空間的無限可能',
      description: `空間就是舞台，每個發生都是漣漪起點。<br/>
      線上直播、線下交流，對外發表、內部培訓，團隊溝通、一人作業，xarehub享聚落 一站式空間服務，你最聰明彈性的選擇！`,
      item: '/banner/home/feature-2.jpg'
    }, {
      // title: '智能辦公空間',
      // description: 'xarehub享聚落採用智能管理系統、自助化使用空間。只需要簡單打開APP閱覽、預定、付款即可使用！不用擔心去到現場沒位子',
      title: '彈性方案由你組合',
      description: `就是享受不綁約的自由！<br/>
      xarehub享聚落 核心理念是提供最適合你與團隊的彈性空間選擇，自主管理時間、自助組合空間，讓你的時間使用效益翻倍。`,
      item: '/banner/home/feature-3.jpg'
    }],
    feature: {
      // title: '享聚落空間',
      title: 'xarehub享聚落 空間',
      // description: 'xarehub享聚落有共享座位、獨立辦公室、4-20人智能會議室、活動場地，甚至直播間、錄podcast等多元空間(在台北各地多數量、小點聚落)，讓你選擇更方便、更靈活安排',
      description: `帶著你的創意力、想像力、執行力，登上你心中的那座山，xarehub享聚落 以不同的面貌支持你！<br class="hidden lg:block"/>空間我們準備好了，等你來實現！`,
      space_cta: {
        title: '查詢更多空間'
      },
    },
    // spaces: [...new Array(9).fill({
    //   src: '/banner/Photo_04@2x.png'
    // })],
    spaces: [{
      src: '/banner/home/carousel-1.jpg'
    }, {
      src: '/banner/home/carousel-2.jpg'
    }, {
      src: '/banner/home/carousel-3.jpg'
    }, {
      src: '/banner/home/carousel-4.jpg'
    }, {
      src: '/banner/home/carousel-5.jpg'
    }]
  }

  const SpaceSlides = ({ spaces, navigation = false, centeredSlides = false, spaceBetween = 0 }) =>
    <div className={`${styles.swiperContainer} space-slides relative w-full z-10 lg:pt-10`}>
      <Swiper
        spaceBetween={spaceBetween}
        centeredSlides={centeredSlides}
        autoplay={{
          "delay": 2500,
          "disableOnInteraction": false
        }}
        pagination={false}
        breakpoints={{
          "0": {
            "slidesPerView": 2,
            // "spaceBetween": 20
          },
          "768": {
            "slidesPerView": 3,
            // "spaceBetween": 40
          },
          "1024": {
            "slidesPerView": 5,
            // "spaceBetween": 50
          }
        }}
        effect={'fade'}
        loop={true}
        speed={200}
        navigation={navigation}
      // className="mySwiper"
      >
        {spaces.map(item => (
          <SwiperSlide key={item}>
            <div className={styles['space-image']}>
              <Image width="306" height="328" layout="responsive" objectFit="cover" src={item.src} loading="eager" alt="" priority={true} />
              {/* <img src="https://images.unsplash.com/photo-1606857521015-7f9fcf423740?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="" className="w-full object-cover" /> */}
            </div>
            {/* ss */}
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <Swiper spaceBetween={30} centeredSlides={true} autoplay={{
        "delay": 2500,
        "disableOnInteraction": false
      }} pagination={{
        "clickable": true
      }} navigation={true} className="mySwiper">
        <SwiperSlide>Slide 1</SwiperSlide><SwiperSlide>Slide 2</SwiperSlide><SwiperSlide>Slide 3</SwiperSlide><SwiperSlide>Slide 4</SwiperSlide><SwiperSlide>Slide 5</SwiperSlide><SwiperSlide>Slide 6</SwiperSlide><SwiperSlide>Slide 7</SwiperSlide><SwiperSlide>Slide 8</SwiperSlide><SwiperSlide>Slide 9</SwiperSlide>
      </Swiper> */}
    </div>

  const FindSpaces = ({ feature, spaces }) => {
    const onClick = () => Router.push('/space')
    return (
      <section className="relative lg:py-10 lg:my-10 overflow-hidden">
        <div className="hidden lg:block home-background z-0 w-full">
          <img className="w-full" src="/home/Background_3.svg" alt="" />
        </div>
        <div className="hidden lg:block">
          <div className="hidden lg:block relative z-10 text-center py-5">
            <h1 className="text-blue title-s1 text-center">{feature.title}</h1>
            <p className={`${styles.spaceDescription} py-5 text-sm text-center m-auto`} dangerouslySetInnerHTML={{ __html: feature.description }}></p>
            <BlueButton text={feature.space_cta.title} onClick={onClick} />
          </div>
          <SpaceSlides spaces={spaces} navigation={true} centeredSlides={true} />
        </div>
        <div className="block lg:hidden pb-5">
          <div className="hiddeb lg:block relative z-10 text-center pt-5 pb-5">
            <h1 className="text-blue title-s1 text-center">{feature.title}</h1>
            <p className={`${styles.spaceDescription} py-5 text-sm text-left lg:text-center m-auto`} dangerouslySetInnerHTML={{ __html: feature.description }}></p>
            <BlueButton text={`搜尋`} onClick={onClick} />
          </div>
          <SpaceSlides spaces={spaces} spaceBetween={20} />
        </div>
      </section>
    )
  }



  const PostsHightLight = ({ blog }) =>
    <section className="text-center relative overflow-hidden pt-5 lg:pb-20">
      <div className="home-background z-0 w-full">
        <img className="w-full hidden lg:block" src="/home/Backgruound_4.svg" alt="" />
        <img className="w-full block lg:hidden" src="/home/Background_2.svg" alt="" />
      </div>
      <div className="z-10 relative lg:top-10">
        <h1 className="text-white text-xl title-s1 text-center py-5 lg:py-20">{blog.title}</h1>
        <div className="flex flex-col lg:flex-row items-center justify-center lg:mb-10">
          {blog.posts.map((post, idx) => (
            <PostItem key={idx} post={post} />
          ))}
        </div>
        <div className="my-5 lg:my-0">
          <BlueButton text={blog.post_cta.title} />
        </div>
      </div>
    </section>

  const FlowFigure = ({ step, idx }) =>
    <div className="flex flex-col">
      <div key={idx} className="flex flex-row items-center">
        <div key={idx} className={`${styles['how-to-use-icon']} flex flex-col justify-center items-center p-2`}>
          <img src={step.src} alt={step.title} className="w-full object-cover" />
        </div>
      </div>
      <div key={idx} className="flex flex-row justify-center items-center">
        <p className="text-center text-blue p-0 lg:p-2 text-lg lg:text-lg">{step.title}</p>
      </div>
    </div>

  const NarrowFigure = () => <Image width="20" height="20" layout="responsive" src={'/home/Path 248.svg'} loading="eager" alt={``} priority={true} />

  const HowToUseFlow = ({ steps }) =>
    <section>
      <div className={`${styles['how-to-use']} relative`}>
        <div className={`container m-auto flex lg:pt-10 lg:pb-36 lg:gap-0 pb-10 flex-wrap lg:flex-nowrap`}>
          {steps.map((step, idx) =>
            <>
              <div key={idx} className="hidden lg:flex flex-row justify-center items-center w-1/2 lg:w-80">
                <FlowFigure step={step} idx={idx} />
                {idx !== steps.length - 1 &&
                  <div className="hidden lg:block">
                    <div className={`${idx % 2 ? 'hidden' : 'flex'} lg:flex justify-center items-center`}>
                      <div className="w-10 mr-2">
                        <NarrowFigure />
                      </div>
                    </div>
                  </div>
                }
              </div>

              <div className="block lg:hidden w-5/12 px-5">
                <FlowFigure step={step} idx={idx} />
              </div>
              {(idx === 0 || idx === 2) &&
                <div className="block lg:hidden w-2/12">
                  <div className="flex justify-center items-center h-full">
                    <div className="w-10 m-auto">
                      <NarrowFigure />
                    </div>
                  </div>
                </div>
              }
              {idx === 1 &&
                <div className="block lg:hidden w-full">
                  <div key={idx} className=" flex flex-row justify-center w-full lg:w-80">
                    <div className={styles.mobileNarrow}>
                      <NarrowFigure />
                    </div>
                  </div>
                </div>
              }
            </>
          )}

          {/* <img className="w-full object-cover absolute bottom-0 left-0" src="/pngwing.com.png" alt="" /> */}
        </div>
      </div>
      {/* <div className="triangle-bottom z-0">
        <img src="https://www.seekpng.com/png/full/127-1277539_arrow-triangle-outline-pointing-left-vector-white-triangular.png" alt="" />
      </div> */}
    </section>

  const SpaceFeatures = ({ features }) =>
    <section className={`${styles['space-features']} relative py-10`}>
      <div className="hidden lg:block lg:absolute z-0 w-full -top-96">
        <img className="w-full" src="/home/Background_01.svg" alt="" />
      </div>
      <div className="hidden lg:block">
        <LeftTriangleOnTop isLargeSize={true} />
      </div>
      <div className="hidden lg:block">
        <RightTriangleOnTop isLargeSize={true} />
      </div>
      <div className="xl:w-[984px] m-auto flex flex-col gap-5 z-10 relative">
        {features.map((feature, idx) =>
          <div key={idx} className="relative z-20">
            <div className={`block lg:hidden relative left-0 z-0`}>
              {idx % 2 ? <RightTriangleOnTop isLargeSize={true} /> : <LeftTriangleOnTop isLargeSize={true} />}
            </div>
            <div key={idx} className={`flex flex-col lg:flex-row items-center justify-between lg:py-10 relative z-10`}>

              <div className={` ${styles['space-feature-image']} ${idx % 2 ? 'round-tr-lr ' : 'lg:order-2  round-tr'} `}>
                {(idx % 2) ? <div className={`${styles.blueRadious} block lg:hidden -v1-z-1`}>
                  {/* <Image width="52" height="33" layout="responsive" objectFit="cover" loading="eager" src={`/home/Path 716.svg`} alt="" priority={true} /> */}
                  <img src={`/home/Path 716.svg`} className="w-full h-full object-cover" alt="" />
                </div> : null}
                {!(idx % 2) ? <div className={`${styles.blueBar} block lg:hidden -v1-z-1`}>
                  {/* <Image width="51" height="9" layout="responsive" objectFit="cover" loading="eager" src={`/home/Path 717.svg`} alt="" priority={true} /> */}
                  <img src={`/home/Path 717.svg`} className="w-full h-full object-cover" alt="" />

                </div> : null}
                {/* <Image width="475" height="319" layout="responsive" objectFit="cover" loading="eager" src={feature.item} alt="" priority={true} /> */}
                <img src={feature.item} className="w-full h-full object-cover" alt="" />
                {/* <img src="https://images.unsplash.com/photo-1606857521015-7f9fcf423740?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="" className="object-cover" /> */}
              </div>
              <div className={`${styles['space-feature-image']} ${idx % 2 ? '' : 'lg:order-1'} lg:px-8 justify-center flex flex-col`}>
                <p className="text-blue title-s1 py-2">{feature.title}</p>
                <p className="p-s2 w-full lg:w-64 xl:w-full text-blue-color" dangerouslySetInnerHTML={{ __html: feature.description }}></p>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>


  return (
    <>
      <Layout>
        {/* <Head>
          <title>{META.title}</title>
          <meta name="viewport" content={META.viewport} />
          <meta name="title" content={META.title} />
          <meta name="description" content={META.description} />
          <meta property="article:publisher" content={META.fbLink} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={META.title} />
          <meta property="og:description" content={META.description} />
          <meta property="og:image" content={META.previewImage} />
          <meta property="og:url" content={META.url} />
          <meta property="og:site_name" content={META.title} />
          <link rel="image_src" href={META.previewImage} />
        </Head> */}
        {/* <section className="title-section flex flex-col text-center justify-center items-center pt-10 pb-20 lg:py-15"> */}
        <SpaceFeatures features={INDEX_COPYWRITTING.features} />
        <HowToUseFlow steps={INDEX_COPYWRITTING.steps} />
        <FindSpaces feature={INDEX_COPYWRITTING.feature} spaces={INDEX_COPYWRITTING.spaces} />
        {/* <PostsHightLight blog={INDEX_COPYWRITTING.blog} /> */}
        <ScrollToTopBtn />
        {/* </section> */}
      </Layout>
    </>
  )
}

export async function getStaticProps() {
  // const allPosts = await getAllPostsForHome(preview)
  return {
    props: {},
  }
}
